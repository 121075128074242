import React from 'react';
import { useGoogleLogout } from 'react-google-login';
import config from '../config';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

function LogoutHooks(props) {
  const onLogoutSuccess = (res) => {
    console.log('Logged out Success');
    localStorage.removeItem('authToken');
    props.history.push('/login');
  };

  const onFailure = () => {
    console.log('Handle failure cases');
    console.log('Logged out Success');
    localStorage.removeItem('authToken');
    props.history.push('/login');
  };

  const { signOut } = useGoogleLogout({
    clientId: config.clientId,
    onLogoutSuccess,
    onFailure,
  });

  return (
    <Button onClick={signOut} variant="link">
        Logout
    </Button>
  );
}

export default withRouter(LogoutHooks);