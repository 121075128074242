import React, { useEffect } from 'react';
import { useGoogleLogin } from 'react-google-login';
import config from '../config';
import { refreshTokenSetup } from '../utils/refreshToken';

function LoginHooks(props) {

    useEffect(() => {
        // Update the document title using the browser API
        let local = localStorage.getItem('authToken');
        if (local) {
            props.history.push('/');
        }
    }, []);

    const onSuccess = (res) => {
        refreshTokenSetup(res);
        props.history.push('/');
    };

    const onFailure = (res) => {
        console.log('Login failed: res:', res);
        alert(`Failed to login. 😢`);
    };

    const { signIn } = useGoogleLogin({
        onSuccess,
        onFailure,
        clientId: config.clientId,
        isSignedIn: true,
        accessType: 'offline',
        // responseType: 'code',
        // prompt: 'consent',
    });

    return (
        <button onClick={signIn} className="button-google">
            <img src="icons/google.svg" alt="google login" className="icon"></img>
            <span className="buttonText">Sign in with Google</span>
        </button>
    );
}

export default LoginHooks;