import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export default function (ComposedComponent) {

  class Authentication extends Component {
    state = {
      valid: false
    }
    
    componentDidMount = async () => {
      const { history } = this.props;
      let local = localStorage.getItem('authToken');
      if (local) {
        this.setState({ valid: true });
      } else {
        history.push('/login');
      }
    }

    render() {
      let local = localStorage.getItem('authToken');
      if (local) {
        if (this.state.valid) {
          return <ComposedComponent {...this.props} />;
        } else {
          return <div>Loading....</div>
        }
      }
      else {
        return <Redirect to="/login" />
      };
    }
  }
  
  return (Authentication);
}
