import React from 'react';
import { Row, Col, Card, Form, Button, Container, Spinner } from 'react-bootstrap';
import MultiSelect from "react-multi-select-component";
import { FaSearch } from 'react-icons/fa';
import axios from 'axios';
import config from '../config';
import Logout from './logout';

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isValid: {
                value: false,
                text: ''
            },
            data: [],
            count: 0,
            isLoading: false,
            query: "",
            error: '',
            selectedCategories: [],
            isSearched: false
        }
    }

    handleTextChange(e) {
        this.setState({ [e.name]: e.value });
    }

    handleSelectedCategories(e) {
        this.setState({ selectedCategories: e });
    }

    removeSelectedCategory(value) {
        let { selectedCategories } = this.state;
        selectedCategories = selectedCategories.filter(x => x.value !== value);
        this.setState({ selectedCategories });
    }

    handleSearchBtn = async (e) => {
        e.preventDefault();
        let { query, selectedCategories } = this.state;
        this.setState({ isLoading: true, error: '', isSearched: true });
        try {
            let categoriesToSend = [];
            selectedCategories.forEach(elem => {
                categoriesToSend.push(elem.value);
            });
            let res = await axios.post(`${config.prod}/api/data/list`,{ query, all: selectedCategories.length === 7 || selectedCategories.length == 0 ? true : false, selectedCategories: categoriesToSend });
            this.setState({ isLoading: false, data: res.data.data, count: res.data.count });
        } catch(err) {
            console.log("Error: ", err);
            this.setState({ isLoading: false, data: [], error: 'Internal Server Error' });
        }
    }

    render() {
        const options = [
            { label: "CEP DO IMOVEL", value: "CEP DO IMOVEL" },
            { label: "CPF/CNPJ DO CONTRIBUINTE 1", value: "CPF/CNPJ DO CONTRIBUINTE 1" },
            { label: "CPF/CNPJ DO CONTRIBUINTE 2", value: "CPF/CNPJ DO CONTRIBUINTE 2" },
            { label: "NOME DE LOGRADOURO DO IMOVEL", value: "NOME DE LOGRADOURO DO IMOVEL" },
            { label: "NOME DO CONTRIBUINTE 1", value: "NOME DO CONTRIBUINTE 1" },
            { label: "NOME DO CONTRIBUINTE 2", value: "NOME DO CONTRIBUINTE 2" },
            { label: "NUMERO DO CONTRIBUINTE", value: "NUMERO DO CONTRIBUINTE" }
        ];
        const customValueRenderer = (selected, _options) => {
            return selected.length
              ? selected.length == 1 ? "1 category selected" :  `${selected.length} categories selected`
              : "Select";
          };
        return (
            <>
                <div className="container-fluid">
                    <Container>
                        <Row className="pt-4">
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Form onSubmit={(e) => this.handleSearchBtn(e) }>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="form-group has-search">
                                                        <span className="fa fa-search form-control-feedback text-dark"><FaSearch /></span>
                                                        <input type="text" name="query" value={this.state.query} onChange={(e)=> this.handleTextChange(e.target)} className="form-control search_field" placeholder="Search" />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <MultiSelect
                                                        className="dropdown_field"
                                                        hasSelectAll={false}
                                                        disableSearch={true}
                                                        options={options}
                                                        hasSelectAll={true}
                                                        selectAllLabel={"All"}
                                                        value={this.state.selectedCategories}
                                                        onChange={(e) => this.handleSelectedCategories(e)}
                                                        labelledBy={"Categories"}
                                                        ClearSelectedIcon={<span></span>}
                                                        valueRenderer={customValueRenderer}
                                                    />
                                                </Col>
                                                <Col md={2}>
                                                    <Button variant="dark" type="submit" disabled={ this.state.query && this.state.query.trim().length > 1 ? false : true }>Search</Button>
                                                    <span style={{ paddingLeft: 2 }}><Logout /></span>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <Row>
                                            <Col>
                                                {   this.state.selectedCategories.map(elem => (
                                                        <div className="chip" key={elem.value}>
                                                            { elem.value }
                                                            <span className="closebtn" onClick={() => this.removeSelectedCategory(elem.value)}>
                                                                &times;
                                                            </span>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    this.state.selectedCategories.length > 1 && <div className="chip">
                                                        Remove All
                                                        <span className="closebtn" onClick={() => this.setState({ selectedCategories: [] })}>
                                                            &times;
                                                        </span>
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                        {
                                            this.state.isLoading ? <Row>
                                                <Col>
                                                    <div className="text-center loader_container">
                                                        <Spinner animation="border" size="lg" variant="secondary" />
                                                    </div>
                                                </Col>
                                            </Row> : null
                                        }
                                        {
                                            !this.state.isLoading && this.state.error ? <Row>
                                                <Col>
                                                    <div className="text-center loader_container">
                                                        <h3 style={{ color: 'red' }}>{ this.state.error }</h3>
                                                    </div>
                                                </Col>
                                            </Row> : this.state.isSearched  ? <Row>
                                                <Col>
                                                    {   !this.state.isLoading && this.state.count ?
                                                        <Row>
                                                            <Col>
                                                                <span>{ `${this.state.count} results` }</span>
                                                            </Col>
                                                        </Row> : null
                                                    }
                                                    {   this.state.data.length ? this.state.data.map(elem => (
                                                            <Row key={elem._id} className="pt-1 pb-1">
                                                                <Col>
                                                                    <Card style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
                                                                        <Card.Header style={{ borderBottom: 'none' }}>
                                                                            <Card.Title style={{ marginBottom: 0 }}><span>{elem["NUMERO DO CONTRIBUINTE"]}</span><span style={{ float: "right"}}>Score: {elem["score"].toFixed(4)}</span></Card.Title>
                                                                        </Card.Header>
                                                                        <Card.Body>
                                                                            <div>
                                                                                Nome do Contribuinte 1: {elem["NOME DO CONTRIBUINTE 1"]}
                                                                            </div>
                                                                            <div>
                                                                                CPF/CNPJ 1: {elem["CPF/CNPJ DO CONTRIBUINTE 1"]}
                                                                            </div>
                                                                            {
                                                                                elem["NOME DO CONTRIBUINTE 2"] && elem["NOME DO CONTRIBUINTE 2"].trim().length ? <div>
                                                                                    Nome do Contribuinte 2: {elem["NOME DO CONTRIBUINTE 2"]}
                                                                                </div> : null
                                                                            }
                                                                            {
                                                                                elem["CPF/CNPJ DO CONTRIBUINTE 2"] && elem["CPF/CNPJ DO CONTRIBUINTE 2"].trim().length ? <div>
                                                                                    CPF/CNPJ 2: {elem["CPF/CNPJ DO CONTRIBUINTE 2"]}
                                                                                </div> : null
                                                                            }
                                                                            <div>
                                                                                Área Construida: {elem["AREA CONSTRUIDA"]}
                                                                            </div>
                                                                            <div>
                                                                                Tipo de Imóvel: {elem["TIPO DE USO DO IMOVEL"]}
                                                                            </div>
                                                                            <div>
                                                                                <div>Endereço:</div>
                                                                                <div>{`${elem["NOME DE LOGRADOURO DO IMOVEL"]}, ${elem["NUMERO DO IMOVEL"]}`}</div>
                                                                                <div>{elem["COMPLEMENTO DO IMOVEL"]}</div>
                                                                                <div>{elem["BAIRRO DO IMOVEL"]}</div>
                                                                                <div>{elem["CEP DO IMOVEL"]}</div>
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        )) : !this.state.isLoading ? <div className="text-center loader_container">
                                                                <h3>No data found against this query</h3>
                                                            </div> : null
                                                    }
                                                </Col>
                                            </Row> : null
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

export default Dashboard;
