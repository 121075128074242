import { Switch, Route } from 'react-router-dom';
import dashboard from './Components/dashboard';
import login from './Components/login';
import RequireAuth from './RequireAuth';

function App() {
  return (
    <div>
      <Switch>
        <Route exact path={"/"} component={RequireAuth(dashboard)} />
        <Route exact path={"/login"} component={login} />  
      </Switch>
    </div>
  );
}

export default App;
